
import { ActionType } from "./types";
import { StateType } from "./state";

export function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case "setStatus": {
      const newState: StateType = {
        ...state,
        status: action.status,
        statusFetched: action.statusFetched
      };
      return newState;
    }
    case "setRemaining": {
      const newState: StateType = {
        ...state,
        remaining: action.remaining,
        remainingFetched: action.remainingFetched
      };
      return newState;
    }
    case "setTransactions": {
      const newState: StateType = {
        ...state,
        transactions: action.transactions,
        transactionsFetched: action.transactionsFetched
      };
      return newState;
    }
    case "setMaxUnits": {
      const newState: StateType = {
        ...state,
        max_units: action.max_units,
        max_unitsFetched: action.max_unitsFetched
      };
      return newState;
    }
    case "setOpenDate": {
      const newState: StateType = {
        ...state,
        open_date: action.open_date,
        open_dateFetched: action.open_dateFetched
      };
      return newState;
    }
    case "setCloseDate": {
      const newState: StateType = {
        ...state,
        close_date: action.close_date,
        close_dateFetched: action.close_dateFetched
      }
      return newState;
    }
    case "setBuyedQuantity": {
      const newState: StateType = {
        ...state,
        buyed_quantity: action.buyed_quantity,
        buyed_quantityFetched: action.buyed_quantityFetched
      };
      return newState;
    }
    case "setCanBuy": {
      const newState: StateType = {
        ...state,
        can_buy: action.can_buy,
        can_buyFetched: action.can_buyFetched
      };
      return newState;
    }
    case "setPrice": {
      const newState: StateType = {
        ...state,
        price: action.price,
        priceFetched: action.priceFetched
      };
      return newState;
    }
    case "setNfts": {
      const newState : StateType = {
        ...state,
        nfts: action.nfts,
        nftsFetched: action.nftsFetched,
      }
      return newState
    }
    case "setTransaction": {
      const newState : StateType = {
        ...state,
        transaction: action.transaction,
        transactionFetched: action.transactionFetched,
      }
      return newState
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}
