import { Address, AddressValue, ProxyProvider } from "@elrondnetwork/erdjs/out";
import { network } from "config";
import { runQuery } from "./runQuery";

export const api = {
  getMaxUnits: async (provider: ProxyProvider) => {
    return await runQuery("max_units", [], provider);
  },

  getStatus: async (provider: ProxyProvider) => {
    return await runQuery("sc_freezed", [], provider);
  },

  getAvaliableNft: async (provider: ProxyProvider) => {
    return await runQuery("available_nft_count", [], provider);
  },

  getPrice: async (provider: ProxyProvider) => {
    return await runQuery("price", [], provider);
  },

  getOpenDate: async (provider: ProxyProvider) => {
    return await runQuery("presale_open", [], provider);
  },

  getCloseDate: async (provider: ProxyProvider) => {
    return await runQuery("presale_close", [], provider);
  },

  getCanBuy: async (provider: ProxyProvider, address: string) => {
    return await runQuery(
      "can_buy",
      [new AddressValue(new Address(address))],
      provider,
    );
  },

  getBuyedQuantity: async (provider: ProxyProvider, address: string) => {
    return await runQuery(
      "buyed_quantity",
      [new AddressValue(new Address(address))],
      provider,
    );
  },

  getNfts: async (address: string, collection: string) => {
    const response = await fetch(
      `${network.apiAddress}/accounts/${address}/nfts?collections=${collection}`,
    );
    const data = await response.json();
    return await data;
  },

  getTransaction: async (txHash: string | null) => {
    console.log(txHash, "doing call");
    try {
      const response = await fetch(
        `${network.apiAddress}/transactions/${txHash}`,
      );
      const data = await response.json();
      return await data;
    } catch (error) {
      console.log(error);
    }
  },

  // Bad try for fetching newly generated nft after buying via query

  // postBuyTransaction: async (provider: ProxyProvider, address: string, amount: number) => {
  //     const args = [
  //         new U8Value(amount),
  //     ]
  //     console.log(args)
  //     return await runQuery(`buy`, args, provider)
  // }
};
