import * as React from "react";
import { useTranslation } from "react-i18next";
import * as Dapp from "@elrondnetwork/dapp";
import bolt from "assets/img/btn-bolt.png";
import { useHistory } from "react-router-dom";

const PageNotFound = () => {

  const history = useHistory();
  const { loggedIn } = Dapp.useContext();
  if (loggedIn) {
    history.replace("/sale")
  }
  const dappLogout = Dapp.useLogout();
  const dappLogin = Dapp.useWebWalletLogin({
    callbackRoute: "/sale",
    token: undefined,
  });

  const { t } = useTranslation();

  const logOut = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogout({ callbackUrl: `${window.location.origin}/` });
  };
  const logIn = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogin();
  };

  return (
    <div className="d-flex flex-fill align-items-center container">
      <div className="row w-100">
        <div className="col-12 mx-auto">
            <div className="card-body text-center justify-content-center">
              {/* <div className="dapp-icon icon-medium">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="mx-auto text-muted fa-3x mb-2"
                />
              </div> */}
              {/* <span className="h3 empty-heading mt-3">Page not found.</span> */}
              <span className="h2 empty-heading">You may need authentication</span>
              <hr />
              {/* <span className="empty-details">{pathname}</span> */}
              <button
                onClick={loggedIn ? logOut : logIn}
                className="btn-primary w-auto">
                <img
                  alt="connect_image"
                  src={bolt}
                />
                <span>{loggedIn ? t("DISCONNECT") : t("CONNECT")}</span>
              </button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
