
const Footer = () => {
  return (
    <footer>
      <div>
        {/*<a
          {...{
            target: "_blank",
          }}
          className="d-flex align-items-center"
          href="https://elrond.com/"
        >
          Made with <HeartIcon className="mx-1" /> by Quabu Solutions.
        </a>*/}
        <p>COPYRIGHT &copy; 2021 GNOGEN STUDIOS, ALL RIGHTS RESERVED. &nbsp; | &nbsp; &nbsp;
          <a 
            {...{
              target: "_blank"
            }}
            href="https://t.me/maiarpunk"
          >TELEGRAM</a> &nbsp; &nbsp;
          <a 
            {...{
              target: "_blank"
            }}
            href="https://twitter.com/Maiarpunks"
          >TWITTER</a> &nbsp; &nbsp;
          <a 
            {...{
              target: "_blank"
            }}
            href="http://maiarpunks.store"
          >MEDIUM</a> &nbsp; &nbsp;
          <a 
            {...{
              target: "_blank"
            }}
            href="http://maiarpunks.store"
          >YOUTUBE</a>
        </p>
        
      </div>
    </footer>
  );
};

export default Footer;
