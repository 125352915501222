import { network } from "config";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import errorIcon from "../../../assets/img/icons8-cancelar-160.png";
import successIcon from "../../../assets/img/icons8-comprobado-160.png";

const SuccessMessage = (props: any) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const history = useHistory();
  console.log(props.message)
  const handleClose = () => {
    setShow(false);
    history.push("/");
    history.push(`/collections?txHash=${props.txHash}`);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      centered
      size="lg"
      className="call-response-popup">
      <Modal.Body className="text-center py-5">
        {props.success ? (
          <img alt="success_image" src={successIcon} />
        ) : (
          <img alt="fail_image" src={errorIcon} />
        )}
        <Modal.Title className="my-4">
          {props.success ? t("SuccessTitle") : t("ErrorTitle")}
        </Modal.Title>
        {!props.success && <p>Response: "{props.message}"</p>}
        <p>{props.success ? t("SuccessMsg") : t("ErrorMsg")}</p>
        <span className="hash-span">
          <a
            rel="noreferrer"
            target="_blank"
            href={`${network.explorerAddress}transactions/${props.txHash}`}>
            {props.txHash}
          </a>
        </span>
      </Modal.Body>
      <Modal.Footer>
        {props.success ? (
          <Button variant="primary" onClick={handleClose}>
            {t("SeeCollection")}
          </Button>
        ) : (
          <Button variant="primary" onClick={() => setShow(false)}>
            {t("Close")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessMessage;
