import * as Dapp from "@elrondnetwork/dapp";
import { api } from "api";
import { useContext, useDispatch } from "context";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { NftCard } from "./NftCard";
import { collection } from "../../config";

const Collections = () => {
  const { t } = useTranslation();
  const { address, loggedIn } = Dapp.useContext();
  const history = useHistory();
  if (!loggedIn) history.replace("/unlock");
  const { nfts, nftsFetched, transaction } = useContext();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { txHash } = Object.fromEntries(query);

  const fetchData = () => {
    api.getNfts(address, collection).then((response) => {
      console.log("NFTS", response);
      dispatch({
        type: "setNfts",
        nfts: response,
        nftsFetched: true,
      });
    });
    api.getTransaction(txHash).then((response) => {
      console.log(response.operations);
      dispatch({
        type: "setTransaction",
        transaction: response,
        transactionFetched: true,
      });
    });
  };
  React.useEffect(fetchData, []);

  const nftCards = () => {
    let identifiers: any[] = [];
    if (transaction !== undefined && transaction.operations) {
      transaction.operations.map((o) => identifiers.push(o.identifier));
      console.log(identifiers);
    }

    let response = [];
    for (let index = 0; index < nfts.length; index++) {
      const nft = nfts[index];
      response.push(
        <NftCard
          title={nft.name}
          description={nft.identifier}
          imgSrc={nft.url}
          price={0.75}
          key={nft.identifier}
          isNew={identifiers.includes(nft.identifier)}
        />,
      );
    }
    return response;
  };

  return (
    <div className="section-container">
      <h1 className="mb-3">{t("COLLECTIONS")}</h1>
      <div className="nft-container row justify-content-center">
        {nftsFetched && nftCards()}
      </div>
    </div>
  );
};

export default Collections;
