import React, {useState} from "react";
import { useTranslation } from "react-i18next";




const FormWhitelist = () => {



  const {t} = useTranslation();
  const [elrondtag, setElrondTag] = useState('');


  const addToWhiteList = (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault();
    // api.getPrice(dapp.proxy)
    // api.getOpenDate(dapp.proxy)
    // api.getCloseDate(dapp.proxy)
    // api.getCanBuy(dapp.proxy, address)
    // api.getBuyedQuantity(dapp.proxy, address)
    // send(transaction)
    // setElrondTag('');
  };

  return (
    <form className="ml-4" onSubmit={addToWhiteList}>
      <div className="form-group row mt-4">
        <label
        htmlFor="ElrondHerotag"
        className="col-sm-3 col-form-label labelForm"
        >
        {t("EnterElrondTag")}:
        </label>
        <div className="col-sm-3">
        <input
            type="text"
            className="form-control form-control-lg"
            id="ElrondHerotag"
            placeholder="@ElrondHerotag"
            value={elrondtag}
            onChange={e => setElrondTag(e.target.value)}
        />
        </div>
      </div>
      <div className="form-group row">
        <button type="submit" className="btn btn-primary mt-5 ml-3">
        {t("AddToWhitelist")}!
        </button>
      </div>
    </form>

  );
};

export default FormWhitelist;
