import * as Dapp from "@elrondnetwork/dapp";
import { api } from "api";
import { contractAddress } from "config";
import { useContext, useDispatch } from "context";
import { RawTransactionType } from "helpers/types";
import useNewTransaction from "pages/Transaction/useNewTransaction";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Banner from "../../components/Banner";
import SuccessMessage from "./TransactionHandler";
import bolt from "assets/img/btn-bolt.png";

const SaleDashboard = () => {
  const { dapp, address, loggedIn } = Dapp.useContext();
  const history = useHistory();
  if (!loggedIn) history.replace("/unlock");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tickets, setTickets] = useState(1);
  const {
    max_units,
    buyed_quantity,
    price,
    transaction,
    can_buyFetched,
    can_buy,
    status,
    remaining,
  } = useContext();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { txHash } = Object.fromEntries(query);

  React.useEffect(() => {
    if (txHash) {
      api.getTransaction(txHash).then((response) => {
        console.log(response);
        dispatch({
          type: "setTransaction",
          transaction: response,
          transactionFetched: response.status,
        });
      });
    }
  }, [txHash]);

  const dappLogin = Dapp.useWebWalletLogin({
    callbackRoute: "/sale",
    token: undefined,
  });

  const fetchData = () => {
    if (loggedIn) {
      api.getMaxUnits(dapp.proxy).then((response) => {
        console.log("MAX_UNITS", response);
        dispatch({
          type: "setMaxUnits",
          max_units:
            response.returnData[0] === ""
              ? 0
              : parseInt(
                  Buffer.from(response.returnData[0], "base64").toString("hex"),
                  16,
                ),
          max_unitsFetched: response.isSuccess(),
        });
      });
      api.getBuyedQuantity(dapp.proxy, address).then((response) => {
        console.log("BUYED_QUANTITY", response);
        dispatch({
          type: "setBuyedQuantity",
          buyed_quantity:
            response.returnData[0] === ""
              ? 0
              : parseInt(
                  Buffer.from(response.returnData[0], "base64").toString("hex"),
                  16,
                ),
          buyed_quantityFetched: response.isSuccess(),
        });
      });
      api.getPrice(dapp.proxy).then((response) => {
        console.log("PRICE", response);
        dispatch({
          type: "setPrice",
          price:
            response.returnData[0] === ""
              ? 0
              : parseInt(
                  Buffer.from(response.returnData[0], "base64").toString("hex"),
                  16,
                ) / 1000000000000000000,
          priceFetched: response.isSuccess(),
        });
      });
      api.getCanBuy(dapp.proxy, address).then((response) => {
        console.log("CAN_BUY", response);
        dispatch({
          type: "setCanBuy",
          can_buy: response.returnMessage,
          can_buyFetched: response.isSuccess(),
        });
      });
      api.getStatus(dapp.proxy).then((response) => {
        console.log("STATUS", { response });
        dispatch({
          type: "setStatus",
          status: response.returnData[0] === "" ? true : false,
          statusFetched: response.isSuccess(),
        });
      });
      api.getAvaliableNft(dapp.proxy).then((response) => {
        console.log("AVIABLE", { response });
        dispatch({
          type: "setRemaining",
          remaining:
            response.returnData[0] === ""
              ? 0
              : parseInt(
                  Buffer.from(response.returnData[0], "base64").toString("hex"),
                  16,
                ),
          remainingFetched: response.isSuccess(),
        });
      });
    }
  };

  React.useEffect(fetchData, [loggedIn]);
  const sendTransaction = Dapp.useSendTransaction();
  const newTransaction = useNewTransaction();

  const send = () => (e: React.MouseEvent) => {
    e.preventDefault();
    let transaction: RawTransactionType = {
      receiver: contractAddress,
      data: tickets > 9 ? `buy@${tickets}` : `buy@0${tickets}`,
      value: `${Math.round((tickets * price) * 100) / 100}`,
      gasLimit: 60000000,
    };
    sendTransaction({
      transaction: newTransaction(transaction),
      callbackRoute: "/sale",
    });
  };

  const logIn = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogin();
  };

  const handleQuantity = (index: number) => {
    let envelopeUnselected = document.querySelector(`#id${tickets}`);
    let envelopeSelected = document.querySelector(`#id${index}`);
    if (envelopeUnselected)
      envelopeUnselected.setAttribute("style", "border: 0");
    if (envelopeSelected)
      envelopeSelected.setAttribute("style", "border: 2px solid black");
    setTickets(index);
  };
  React.useEffect(() => handleQuantity(tickets), []);

  let buttonEnvelopes = [];
  for (let index = 1; index < 8; index++) {
    const element = (
      <span className="btn-envelope" id={`id${index}`}>
        <button
          onClick={(e) => handleQuantity(index)}
          className="btn-quantity p-0">
          {index}
        </button>
      </span>
    );
    buttonEnvelopes.push(element);
  }

  return (
    <div className="span">
      <Banner
        status={status}
        eligibility={max_units}
        gensRemaining={remaining}
        pricePerGnogen={price}
      />
      <div className="section-container">
        <h1 className="mb-3">{t("ForSaleWhile")}</h1>
        <h5 className="mb-5">{t("SelectQuantity")}</h5>
        <div className="row btn-row">{buttonEnvelopes}</div>
        <div className="calculation-container row">
          <div className="calculation">
            {[
              `${tickets}`,
              "x",
              `${price} EGLD`,
              "=",
              `${Math.round((tickets * price) * 100) / 100} EGLD`,
            ].map((x) => (
              <span>{x}</span>
            ))}
          </div>
        </div>
        <h1 className="amount">{Math.round((tickets * price) * 100) / 100} EGLD</h1>
        <button
          onClick={loggedIn ? send() : (e) => logIn}
          disabled={
            loggedIn && (buyed_quantity + tickets > max_units || can_buy !== "")
          }
          className="btn-primary mb-3">
          {!loggedIn && <img alt="login_button" src={bolt} />}
          {loggedIn ? t("PURCHASE") : t("CONNECT")}
        </button>
        {loggedIn && (
          <div>
            {can_buyFetched && <h4 className="text-danger">{can_buy}</h4>}
            <p className="text-muted m-0">
              {t("NoteMax") + max_units + t("PerWalletAddress")}.
            </p>
            <p className="text-muted">
              {t("Minted") +
                buyed_quantity +
                t("CanStillMint") +
                Math.max(max_units - buyed_quantity, 0)}
              .
            </p>
          </div>
        )}
      </div>
      {transaction !== undefined && transaction.status === "success" && (
        <SuccessMessage
          message={transaction.results[0].returnMessage}
          txHash={txHash}
          success
        />
      )}
      {transaction !== undefined && transaction.status === "fail" && (
        <SuccessMessage
          message={transaction.results[0].returnMessage}
          txHash={txHash}
        />
      )}
    </div>
  );
};

export default SaleDashboard;
