import { useState } from "react";

export function NftCard(props: any) {
  const [hovered, setHovered] = useState(false);

  return (
    <div className="col-auto mb-4">
      <div className="card nft-card text-left">
        {props.isNew && (
          <span className={"new-badge " + (hovered ? "new-badge-hidden" : "")}>
            <span>NEW</span>
          </span>
        )}
        <img className="ntf-card-img" alt="nft_image" src={props.imgSrc} />
        <div className="card-body m-0 p-3 pb-0">
          <h5>{props.title}</h5>
          <span>{props.description}</span>
        </div>
        {/* <div className="row">
          <button>
            <img src={ElrondLogo} alt="" />
            {props.price} EGLD
          </button>
        </div> */}
      </div>
    </div>
  );
}
