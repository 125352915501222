import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { dAppName } from "config";
import withPageTitle from "./components/PageTitle";
import FormWhitelist from "./pages/FormWhitelist";
import Sale from "./pages/SaleDashboard";
import Collections from "./pages/Collections";
import DistributionDashboard from "./pages/DistributionDashboard"
import Unlock from "./components/PageNotFoud";
import Home from "./pages/Home";

type RouteType = Dapp.RouteType & { title: string };

export const routeNames = {
  home: "/",
  transaction: "/transaction",
  dashboard: "/dashboard",
  unlock: "/unlock",
  waitList: "/waitlist",
  collections: "/collections"
};

const routes: RouteType[] = [
  {
    path: "/",
    title: "Home",
    component: Home,
  },
  {
    path: "/unlock",
    title: "Unlock",
    component: Unlock,
  },
  {
    path: "/sale",
    title: "Sale",
    component: Sale,
  },
  {
    path: "/collections",
    title: "Collections",
    component: Collections,
  },
  {
    path: "/distribution-dashboard",
    title: "Distribution dashboard",
    component: DistributionDashboard,
    authenticatedRoute: true,
  },
  // {
  //   path: "/transaction",
  //   title: "Transaction",
  //   component: Transaction,
  // },
  {
    path: "/form-whitelist",
    title: "FormWhitelist",
    component: FormWhitelist,
  },
];

const wrappedRoutes = () =>
  routes.map((route) => {
    const title = route.title
      ? `${route.title} • Elrond ${dAppName}`
      : `Elrond ${dAppName}`;
    return {
      path: route.path,
      authenticatedRoute: Boolean(route.authenticatedRoute),
      component: withPageTitle(
        title,
        route.component,
      ) as any as React.ComponentClass<any, any>,
    };
  });

export default wrappedRoutes();
