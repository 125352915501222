import * as Dapp from "@elrondnetwork/dapp";
import { Route, Switch } from "react-router-dom";
import Layout from "./components/Layout";
import PageNotFoud from "./components/PageNotFoud";
import * as config from "./config";
import { ContextProvider } from "./context";
import routes from "./routes";

export default function App() {
  return (
    <Dapp.Context config={config}>
      <ContextProvider> 
        <Layout>
          <Switch>
              <Route
                path="/ledger" /* must correspond to ledgerRoute */
                component={() => (
                  <Dapp.Pages.Ledger callbackRoute="/dashboard" />
                )}
                exact={true}
              />
              <Route
                path="/walletconnect" /* must correspond to walletConnectRoute */
                component={() => (
                  <Dapp.Pages.WalletConnect
                    callbackRoute="/dashboard"
                    logoutRoute="/home" /* redirect after logout */
                    title="Maiar Login"
                    lead="Scan the QR code using Maiar"
                  />
                )}
                exact={true}
              />

              {routes.map((route, i) => (
                <Route
                  path={route.path}
                  key={route.path + i}
                  component={route.component}
                  exact={true}
                />
              ))}
              <Route component={PageNotFoud} />
            </Switch>
        </Layout>
      </ContextProvider>
    </Dapp.Context>
  );
}
