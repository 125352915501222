interface ScResultType {
  callType: string;
  gasLimit: number;
  gasPrice: number;
  nonce: number;
  prevTxHash: string;
  receiver?: string;
  sender: string;
  value: string;
  data?: string;
  returnMessage?: string;
}

type TxStatusType = "pending" | "notExecuted" | "success" | "fail";

export interface TransactionType {
  fee?: string;
  blockHash: string;
  data: string;
  gasLimit: number;
  gasPrice: number;
  gasUsed: string;
  txHash: string;
  miniBlockHash: string;
  nonce: number;
  receiver: string;
  receiverShard: number;
  round: number;
  sender: string;
  senderShard: number;
  signature: string;
  status: TxStatusType;
  timestamp: number;
  value: string;
  scResults?: ScResultType[];
  operations: any[];
  results: any;
}

interface NftType{
  name: String;
  identifier: String;
  url: String;
}
export interface StateType {
  transactions: TransactionType[];
  balance: number;
  max_units: number; // maximum number of claimable units
  price: number; // te price in EGLD of each unit
  open_date: number; // countdown starting date
  close_date: number; // countdown ending date
  can_buy: string // indicates is the user can buy
  buyed_quantity: number; // quantity of bought units
  nfts: NftType[]; // collection of already both, claimed and owned, nfts
  status: boolean;
  remaining: number;
  transaction: TransactionType | undefined;
  transactionFetched: boolean | undefined;
  transactionsFetched: boolean | undefined;
  balanceFetched: boolean | undefined;
  max_unitsFetched: boolean | undefined;
  priceFetched: boolean | undefined;
  open_dateFetched: boolean | undefined;
  close_dateFetched: boolean | undefined;
  can_buyFetched: boolean | undefined;
  buyed_quantityFetched: boolean | undefined;
  nftsFetched: boolean | undefined;
  statusFetched: boolean | undefined;
  remainingFetched: boolean | undefined;
}

const initialState = (): StateType => {
  return {
    transactions: [],
    balance: 0,
    max_units: 0,
    price: 0,
    open_date: 1,
    close_date: 2,
    can_buy: "",
    buyed_quantity: 0,
    nfts: [],
    status: false,
    remaining: 0,
    remainingFetched: undefined,
    transaction: undefined,
    transactionFetched: undefined,
    transactionsFetched: undefined,
    balanceFetched: undefined,
    max_unitsFetched: undefined,
    priceFetched: undefined,
    open_dateFetched: undefined,
    close_dateFetched: undefined,
    can_buyFetched: undefined,
    buyed_quantityFetched: undefined,
    nftsFetched: undefined,
    statusFetched: undefined
  };
};

export default initialState;
