import { contractAddress } from "config";
import { 
    SmartContract, 
    Address, 
    ContractFunction, 
    ProxyProvider, 
    TypedValue 
} from "@elrondnetwork/erdjs";


export const runQuery = async (function_name: string, args: TypedValue[], provider: ProxyProvider) =>{

    let contract = new SmartContract({ address: new Address(contractAddress) });
    let response = await contract.runQuery(provider, {
        func: new ContractFunction(function_name),
        args: args
    });
    return response;
}