import * as Dapp from "@elrondnetwork/dapp";
import React from "react";
import { useTranslation } from "react-i18next";
import BrandImage from "../../../assets/img/maiarpunks-logo.png";
import bolt from "assets/img/btn-bolt.png"

const Navbar = () => {
  const { loggedIn } = Dapp.useContext();
  const dappLogout = Dapp.useLogout();
  const dappLogin = Dapp.useWebWalletLogin({
    callbackRoute: "/sale",
    token: undefined,
  });

  const { t } = useTranslation();

  const logOut = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogout({ callbackUrl: `${window.location.origin}/` });
  };
  const logIn = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogin();
  };

  const navItems = [
    { name: t("WorldRankings"), href: "http://maiarpunks.store", target:"_blank"},
    { name: t("Collections"), href: "/collections", target:""},
    { name: t("Vaults"), href: "http://maiarpunks.store", target:"_blank"},
    { name: t("Nexus"), href: "http://maiarpunks.store", target:"_blank"},
    { name: t("DuoLaunchpad"), href: "http://maiarpunks.store", target:"_blank"},
    { name: t("Spotlight"), href: "http://maiarpunks.store", target:"_blank"},
    { name: t("Whitepaper"), href: "http://maiarpunks.store",target:"_blank"},
  ];

  const menuElements = navItems.map(function (item) {
    return (
      <li className="text-center" key={item.name}>
        <a href={item.href} target={item.target}>{item.name}</a>
      </li>
    );
  });

  return (
    <div className="nav-container w-100">
      <div className="brand">
        <a href="/sale">
          <img alt="brand_image" src={BrandImage} />
        </a>
      </div>
      <ul className="primary-navigation">{menuElements}</ul>
      <button
        onClick={loggedIn ? logOut : logIn}
        className="btn-primary">
        <img
          alt="connect_image"
          src={bolt}
        />
        <span>{loggedIn ? t("DISCONNECT") : t("CONNECT")}</span>
      </button>
    </div>
  );
};

export default Navbar;
