import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { useContext, useDispatch } from "context";
import { api } from "api";
import React from "react";

const DistributionDashboard = () => {

  const {t} = useTranslation();

  const {
    dapp,
  } = Dapp.useContext();

  const dispatch = useDispatch();

  const { open_date, open_dateFetched,
    close_date, close_dateFetched,
  } = useContext();

  const fetchData = () => {
    api.getOpenDate(dapp.proxy)
    .then((response) => {
      console.log(response)
      dispatch({
        type: "setOpenDate",
        open_date: parseInt(Buffer.from(response.returnData[0], "base64").toString("hex"), 16),
        open_dateFetched: response.isSuccess(),
      });
    });

    api.getCloseDate(dapp.proxy)
    .then((response) => {
      console.log(response)
      dispatch({
        type: "setCloseDate",
        close_date: parseInt(Buffer.from(response.returnData[0], "base64").toString("hex"), 16),
        close_dateFetched: response.isSuccess(),
      });
    });
  }

  React.useEffect(fetchData, []);

  const minuteSeconds = 60;
  const hourSeconds = 3600;
  const daySeconds = 86400;
  const yearSeconds = 86400*365;

  const timerProps = {
    isPlaying: true,
    size: 200,
    colors:"#000000",
    strokeWidth: 6,
    trailColor: "#000000"
  };

  const renderTime = (dimension: string, time: number) => {
    return (
      <div className="countdown-time-wrapper text-md-center">
        <h5 className="countdown-time display-3 m-0">{time}</h5>
        <h5>{t(dimension)}</h5>
      </div>
    );
  };

  const getDifference = (endTime: number, nowTime: number) => {
    var difference = endTime - nowTime;
    var d = Math.floor(difference / (1000 * 60 * 60 * 24));
    var h = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    var s = Math.floor((difference % (1000 * 60)) / 1000);
    return {d, h, m, s}
  }
 
  const target = new Date(); //Fecha para la cuenta atras

  var {d, h, m, s} = getDifference(target.getTime()+(20*daySeconds*1000), target.getTime())

  const [claimBtnReady, setClaimBtnReady] = useState(false)

  const [timeRemaining, setTimeRemaining] = useState({
    daysRemaining: d,
    hoursRemaining: h,
    minutesRemaining: m,
    secondsRemaining: s
  });

  useEffect(() => {
    let interval = setInterval(() => {
      var {d, h, m, s} = getDifference(target.getTime()+(20*daySeconds*1000), new Date().getTime())
      setTimeRemaining({
        daysRemaining: d,
        hoursRemaining: h,
        minutesRemaining: m,
        secondsRemaining: s
      })
      if (d <= 0 && h <= 0 && m <= 0 && s <= 0) {
        setClaimBtnReady(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  var {daysRemaining, hoursRemaining, minutesRemaining, secondsRemaining} = timeRemaining

  return (
    <div >
    <div className="row d-flex justify-content-center mb-5">
      <div className="col-auto mt-5">
        <CountdownCircleTimer
          {...timerProps}
          isPlaying={daysRemaining <= 0 && !claimBtnReady}
          duration={yearSeconds}
          initialRemainingTime={(yearSeconds-(yearSeconds-(daysRemaining*daySeconds)))%yearSeconds}
          >
          {renderTime("DAYS", Math.max(daysRemaining, 0))}
      </CountdownCircleTimer>
      </div>
      <div className="col-auto mt-5">
        <CountdownCircleTimer
          {...timerProps}
          isPlaying={hoursRemaining <= 0 && !claimBtnReady}
          duration={daySeconds}
          initialRemainingTime={(daySeconds-(daySeconds-(hoursRemaining*hourSeconds)))%daySeconds}
          onComplete={(totalElapsedTime) => [
            hoursRemaining > 0 && !claimBtnReady , 0
          ]}
          >
          {renderTime("HOURS", Math.max(hoursRemaining, 0))}
        </CountdownCircleTimer>
      </div>
      <div className="col-auto mt-5">
        <CountdownCircleTimer
          {...timerProps}
          isPlaying={minutesRemaining <= 0 && !claimBtnReady}
          duration={hourSeconds}
          initialRemainingTime={(hourSeconds-(hourSeconds-(minutesRemaining*minuteSeconds)))%hourSeconds}
          onComplete={(totalElapsedTime) => [
            minutesRemaining > 0 && !claimBtnReady , 0
          ]}
        >
          {renderTime("MINUTES", Math.max(minutesRemaining, 0))}
        </CountdownCircleTimer>
      </div>
      
      <div className="col-auto mt-5">
        <CountdownCircleTimer
          {...timerProps}
          isPlaying={!claimBtnReady}
          duration={minuteSeconds}
          initialRemainingTime={(minuteSeconds-(minuteSeconds-secondsRemaining))%minuteSeconds}
          onComplete={(totalElapsedTime) => [
            secondsRemaining > 0 && !claimBtnReady , 0
          ]}
        >
          {renderTime("SECONDS", Math.max(secondsRemaining, 0))}
        </CountdownCircleTimer>
      </div>
      
    </div>
      <div className="d-flex row justify-content-center">
        <button disabled={!claimBtnReady} className="btn-primary">{t("Claim")} !</button>
        {!open_dateFetched && (
          <p>{open_date}</p>
        )}
        {!close_dateFetched && (
          <p>{close_date}</p>
        )}
      </div>
    </div>
  );
}

export default DistributionDashboard;