import { useTranslation } from "react-i18next";
import image from "../assets/img/image_banner.png";


const Banner = function (props: any) {

  const { t } = useTranslation();
  console.log(props.status)
  return (
    <div className="my-banner">
      <img className="imageBanner" src={image} alt="image_banner" />
      <h6>10K {t("GenProject")}</h6>
      <div className="col-md-12 mt-10">
        <div className="row">
          <div className="col-md-3">
            <h2>{props.status? t("GOOD") : t("BLOCKED") }</h2>
            <h5 className="text-center">{t("CONNECTSTATUS")}</h5>
          </div>
          <div className="col-md-3">
            <h2 className="text-center">{props.eligibility}</h2>
            <h5 className="text-center">{t("ELIGIBILITY")}</h5>
          </div>
          <div className="col-md-3">
            <h2 className="text-center">{props.gensRemaining}</h2>
            <h5 className="text-center">{t("GNOGENSREMAINING")}</h5>
          </div>
          <div className="col-md-3">
            <h2 className="text-center">{props.pricePerGnogen} EGLD</h2>
            <h5 className="text-center">{t("PRICEGNOGEN")}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
